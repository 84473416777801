import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concat, delay, skip, switchMap, tap } from 'rxjs/operators';
import { OperatingSystem } from '@enums';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private platform: Platform) {}

  public get os() {
    if (navigator.platform.match('Mac')) {
      return OperatingSystem.Mac;
    }
    if (navigator.platform.match('Win')) {
      return OperatingSystem.Win;
    }
    if (navigator.platform.match('Linux')) {
      return OperatingSystem.Linux;
    }
    return null;
  }

  public createNewPollingObservable<T>(request: () => Observable<T>, pollingRate: number): Observable<T> {
    const trigger = new BehaviorSubject(null);

    const whenToRefresh = of(null).pipe(
      delay(pollingRate),
      tap((_) => trigger.next(null)),
      skip(1)
    );

    return trigger.pipe(switchMap(() => request().pipe(concat(whenToRefresh))));
  }

  /**
   * JSON.stringify an object and download it a a .json file
   * Code taken from https://stackoverflow.com/a/30800715/7926620
   */
  public downloadObjectAsJson(exportObject: object, exportName: string) {
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObject, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', exportName + '.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  public checkIfPlatformSupported(): {
    isSupported: boolean;
    message?: string;
  } {
    return { isSupported: true };
  }
}
